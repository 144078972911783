/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <div className="bio">
      <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["auto", "webp", "avif"]}
        src="../images/profile-pic.jpg"
        width={50}
        height={50}
        quality={95}
        alt="Profile picture"
      />
      {author?.name && (
        <p>
          Composed by <strong>{author.name}</strong> {author?.summary || null}
          {`, an aspiring writer trying to make the best use of his quarantine. Follow me on `}
         
          <a href={"https://www.linkedin.com/in/nickhsuu"}>
            LinkedIn 
          </a> 
          <span> and </span>  
          <a href={"https://www.instagram.com/nickhsuu/"}>
             Instagram
          </a> 
          <span>.</span>
        </p>
        
      )}
    </div>
  )
}

export default Bio
